
import OnvocadoLogo from "~/static/logo/logo_light.svg?inline";
import FacebookIcon from "~/static/icons/facebook.svg?inline";
import LinkedInIcon from "~/static/icons/linkedin.svg?inline";
import InstagramIcon from "~/static/icons/instagram.svg?inline";

export default {
  name: "Footer",
  async fetch() {
    await this.$store.dispatch('comparison/fetchComparisons', this.$i18n.locale || 'en');
  },
  components: {
    OnvocadoLogo, FacebookIcon, LinkedInIcon, InstagramIcon
  },
  computed: {
    year() {
      const now = new Date()
      return now.getFullYear()
    },
    comparisons() {
      return this.$store.state.comparison.comparisons[this.$i18n.locale] || [];
    },
  },
  watch: {
    '$i18n.locale'(newLocale) {
      this.$store.dispatch('comparison/fetchComparisons', newLocale);
    },
  },
  data() {
    return {
      showIllustrationCredits: false,
    };
  },
  created() {
    this.showIllustrationCredits = this.$route.name === "product___en" || this.$route.name === "product___bg"
  }
}
